/* buttons */
@import "colors";

.btn {
  width: 218px;
  height: 59px;
  border: none;
  border-radius: 37px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.25;
  }

  &.btn-label-normal {
    text-transform: none;
  }
}

.btn-sm-pd {
  width: 149px;
}

.btn-primary-rounded {
  color: $white;
  background: $brand-primary;
}

.btn-black-rounded {
  color: $white;
  border: 1px solid $white;
  background: transparent;
}

@media screen and (max-width: 768px) {
  .btn {
    text-transform: none;
  }
}

.lang-de {
  .btn {
    width: 238px;
  }

  .btn-sm-pd {
    width: 172px;
  }
}
