$white: #fff;
$black: #000;
$grey-light: rgb(151, 151, 151);
$grey-very-light: rgb(216, 216, 216);

// brand colors
$brand-primary: rgb(212, 173, 55);

// interface colors
$body-bg: rgb(27, 27, 27);
$text-dark: rgb(27, 27, 27);
$success: rgb(119, 200, 151);
$danger: rgb(224, 32, 32);

$recording: rgb(255, 20, 20);
