@import "colors";
@import "typography";
@import "buttons";
@import "echom-material-theme";
@import "toastr";

/* Global Styles */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  color: $white;
  background-color: $body-bg;
  font-family: "Exo", sans-serif;
  font-weight: normal;
  user-select: none;
}

.display-none {
  display: none !important;
}

.w-100 {
  width: 100%;
}

.text-link {
  font-size: 18px;
  color: $white;
  text-decoration: underline;
  cursor: pointer;
}

/* Material styles */
.mat-mdc-checkbox label {
  color: $white;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  background-color: $grey-very-light !important;
  border-color: $grey-very-light !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: $white !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding-top: 5px;
}

@media screen and (max-width: 768px) {
  .desktop-only {
    display: none !important;
  }

  .text-link {
    font-size: 16px;
  }
}

@media screen and (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}

.lang-de {
  .steps-cont {
    @media screen and (max-width: 768px) {
      gap: 10% !important;
      justify-content: center;
    }

    .step-cont {
      .line-linkage {
        left: 40px !important;
        width: 120px !important;
      }
    }
  }
}
