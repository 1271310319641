h1 {
  font-size: 40px;
  font-weight: bold;

  margin-top: 0;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
}

.text-uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 30px;
    line-height: 38px;

    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  h1 {
    font-size: 36px;
    line-height: 38px;

    margin-bottom: 15px;
  }
}
